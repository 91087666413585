import Vue from "vue"
import App from "./App.vue"
import router from "./router"

import Buefy from "buefy"

import VueAnalytics from "vue-analytics"

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === "production"

Vue.use(VueAnalytics, {
  id: "UA-84781434-1",
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
})

Vue.use(Buefy)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
