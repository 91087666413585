<template>
  <div>
    <section
      id="hero-home"
      class="hero has-text-centered is-dark hero-emphasis"
    >
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column feature-text">
              <h1 class="title has-text-white is-size-1">
                Australia's Leaders in Tech & Digital.<br />
              </h1>
              <br />
              <router-link class="button is-info" to="/contact">
                Connect with us
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="clients level">
      <div class="client-logo" v-for="client in clients" :key="client.prefix">
        <div>
          <img :src="'/img/client-logo/' + client.prefix + '.svg'" />
        </div>
      </div>
    </section>

    <section class="feature-content">
      <div class="columns home-content">
        <div class="column is-two-thirds-tablet is-full-mobile home-copy">
          <h2 class="title is-size-1">Better Technology</h2>
          <p class="subtitle is-size-4">
            Delivering precise solutions that are flexible, innovative and
            engineered for performance and stability.
          </p>
        </div>
        <div class="column">
          <img src="/img/feature-content-svg/Solution.svg" alt="" />
        </div>
      </div>
      <div class="columns home-content">
        <div class="column is-two-thirds-tablet is-full-mobile home-copy">
          <h2 class="title is-size-1">Better Solutions</h2>
          <p class="subtitle is-size-4">
            According to our client's needs, we offer bespoke solutions to
            address a wide range of needs from consulting to training.
          </p>
        </div>
        <div class="column">
          <img src="/img/feature-content-svg/Implementation.svg" alt="" />
        </div>
      </div>
      <div class="columns home-content">
        <div class="column is-two-thirds-tablet is-full-mobile home-copy">
          <h2 class="title is-size-1">Better Outcomes</h2>
          <p class="subtitle is-size-4">
            20+ years of experience in delivering hundreds of solutions to a
            diverse range of Australian governments &amp; private sector
            organisations around the world.
          </p>
        </div>
        <div class="column">
          <img src="/img/feature-content-svg/Outcome.svg" alt="" />
        </div>
      </div>
    </section>
  </div>
</template>

<style type="text/css" scoped>
#hero-home {
  position: relative;
}

#hero-home:after {
  content: "\A";
  background-image: url("/img/feature-perth.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.7);
  background-blend-mode: darken;
  height: 100%;
  width: 100%;
  position: absolute;
}

#hero-image {
  background: none;
  width: 100%;
  height: 100%;
}

#hero-container {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
}

#hero-home {
  position: relative;
}

.feature-content img {
  display: flex;
  width: 20rem;
  height: 20rem;
}

.hero-body {
  z-index: 1;
}

.title {
  padding-bottom: 1rem;
}

.hero-body {
  background: url("/img/feature-perth.jpg");
  background-size: cover;
  background-position-y: center;
  background-color: rgba(0, 0, 0, 0.77);
  background-blend-mode: darken;
}

.home-content {
  display: flex;
  align-items: center;
}

.feature-content {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.clients {
  padding-top: 5rem;
  padding-bottom: 3rem;
  padding-left: 5rem;
  padding-right: 5rem;
}

.client-logo img {
  max-height: 5rem;
  filter: grayscale();
  padding: 1rem;
}

.client-logo img:hover {
  filter: none;
  cursor: pointer;
  transition: 0.4s ease;
}

@media screen and (max-width: 768px) {
  .feature-content {
    padding-left: 10%;
    padding-right: 5%;
  }

  .clients {
    display: none;
  }

  .feature-content img {
    display: none;
  }

  .home-content {
    padding-bottom: 10%;
  }
}

@media screen and (min-width: 769px) {
  .feature-text {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .feature-content {
    padding-left: 10%;
    padding-right: 5%;
  }

  .feature-content img {
    width: 15rem;
    height: 20rem;
  }

  .feature-content p {
    padding-right: 12rem;
  }
}

@media screen and (min-width: 900px) {
  #hero-image {
    background-size: cover;
    background-position: center;
  }

  .feature-content {
    padding-left: 20%;
    padding-right: 10%;
  }

  .feature-content img {
    width: 15rem;
    height: 20rem;
  }

  .feature-content p {
    padding-right: 12rem;
  }
}

@media screen and (min-width: 1024px) {
  #hero-image {
    background-size: cover;
    background-position: center;
  }

  .feature-content {
    padding-left: 20%;
    padding-right: 10%;
  }

  .feature-content img {
    width: 15rem;
    height: 20rem;
  }

  .feature-content p {
    padding-right: 12rem;
  }
}
</style>

<script>
export default {
  name: "home",
  data() {
    return {
      clients: [
        { prefix: "curtin" },
        { prefix: "toyota" },
        { prefix: "queensland" },
        { prefix: "inpex" },
        { prefix: "uwa" },
        { prefix: "nsw" },
        { prefix: "ibm" }
      ]
    }
  }
}
</script>
