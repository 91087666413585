<template>
  <nav
    class="navbar navbar-primary is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div id="site-header" class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          <img src="/logo.png" />
        </a>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarMain"
          @click="toggleActive()"
          v-bind:class="{ 'is-active': is_active }"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div
        id="navbarMain"
        class="navbar-menu"
        :class="{
          'navbar-secondary-active': $route.meta.secondary,
          'is-active': is_active
        }"
      >
        <div class="navbar-end desktop">
          <router-link class="navbar-item" to="/">Home</router-link>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">Solutions</a>
            <div class="navbar-dropdown is-boxed">
              <router-link
                class="navbar-item"
                v-for="navItem in navItems_solutions"
                :key="navItem.id"
                :to="navItem.to"
                >{{ navItem.title }}</router-link
              >
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">Services</a>
            <div class="navbar-dropdown is-boxed">
              <router-link
                class="navbar-item"
                v-for="navItem in navItems_services"
                :key="navItem.id"
                :to="navItem.to"
                >{{ navItem.title }}</router-link
              >
            </div>
          </div>
          <router-link class="navbar-item" to="/about">About</router-link>
          <router-link class="navbar-item" to="/contact">Contact</router-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "SiteHeader",
  props: {
    secondary_active: Boolean
  },
  data: function () {
    return {
      is_active: false,
      navItems_solutions: [
        {
          id: "portals-and-extranets",
          title: "Portals and extranets",
          to: "/solutions/portals-and-extranets"
        },
        {
          id: "intranet",
          title: "Intranet Systems",
          to: "/solutions/intranet"
        },
        {
          id: "document-management",
          title: "Document Management",
          to: "/solutions/document-management"
        },
        {
          id: "content-management",
          title: "Content Management",
          to: "/solutions/content-management"
        }
      ],
      navItems_services: [
        { id: "hosting", title: "Hosting", to: "/services/hosting" },
        {
          id: "maintenance",
          title: "Maintenance",
          to: "/services/maintenance"
        },
        { id: "support", title: "Support", to: "/services/support" },
        // { id: 'managed-services', title: 'Managed-Services', to: '/services/managed-services' },
        { id: "training", title: "Training", to: "/services/training" },
        {
          id: "optimisation",
          title: "Optimisation",
          to: "/services/optimisation"
        }
      ],
      navItems_mobile: [{ id: "home", title: "Home" }]
    }
  },
  computed: {
    baseURL: () => window.location.origin
  },
  methods: {
    toggleActive: function () {
      this.is_active = !this.is_active
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 1024px) {
  .navbar-dropdown {
    visibility: visible !important;
  }
}
</style>
