import Vue from "vue"
import Router from "vue-router"
import Home from "./views/Home.vue"

Vue.use(Router)

export default new Router({
  mode: "history",
  linkExactActiveClass: "is-active",
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth"
      }
    }
    return { x: 1, y: 0 }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue")
    },
    {
      path: "/solutions/portals-and-extranets",
      name: "solutions/portals-and-extranets",
      component: () => import("./views/Solutions/Portals-and-extranets.vue")
    },
    {
      path: "/solutions/intranet",
      name: "solutions/intranet",
      component: () => import("./views/Solutions/Intranet.vue")
    },
    {
      path: "/solutions/document-management",
      name: "solutions/document-management",
      component: () => import("./views/Solutions/Document-management.vue")
    },
    {
      path: "/solutions/content-management",
      name: "/content-management",
      component: () => import("./views/Solutions/Content-management.vue")
    },
    {
      path: "/services/hosting",
      name: "services/hosting",
      component: () => import("./views/Services/Hosting.vue")
    },
    {
      path: "/services/support",
      name: "services/support",
      component: () => import("./views/Services/Support.vue")
    },
    {
      path: "/services/maintenance",
      name: "services/maintenance",
      component: () => import("./views/Services/Maintenance.vue")
    },
    // {
    //   path: '/services/managed-services',
    //   name: 'services/managed-services',
    //   component: () => import('./views/Services/Managed-services.vue')
    // },
    {
      path: "/services/training",
      name: "services/training",
      component: () => import("./views/Services/Training.vue")
    },
    {
      path: "/services/optimisation",
      name: "services/optimisation",
      component: () => import("./views/Services/Optimisation.vue")
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About.vue")
    }
  ]
})
