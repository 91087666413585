<template>
  <div class="site-footer">
    <div class="site-footer-details has-background-grey-dark has-text-white">
      <div class="container">
        <div class="columns">
          <div class="column is-hidden-mobile">
            <h2>
              <strong class="has-text-white">Better.Together.</strong>
            </h2>
            <div>Our philosophy is simple.</div>
            <div>
              We listen to your needs and deliver precise, flexible, and
              innovative solutions
            </div>
            <div>engineered for performance and stability.</div>
            <br />
          </div>
          <div class="column has-text-right">
            <strong class="has-text-white">Unified</strong>
            <br />Call (+61) 1300 864 343
            <br />
            <br />
            <router-link class="button is-info" to="/contact">
              Connect with us
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <footer
      class="footer site-footer-notices has-background-grey-lighter has-text-grey-dark has-text-small"
    >
      <div class="container">
        <div class="columns">
          <div class="column has-text-right">
            Copyright &copy; Unified 2023.<br />
            All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "SiteFooter"
}
</script>

<style type="text/css">
.site-footer > div {
  padding: 1em;
}

.site-footer-details .columns {
  padding: 90px 0px;
}
</style>
